import React, { VFC } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import ReactMarkdown from "react-markdown";
import { withContext } from "~context";
import { MetaProps } from "~components/templates/seo";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { h6toFigcaptionPlugin } from "~utils/markdown";
import * as styles from "./index.module.scss";
import { useEffect } from "react";

type Props = {
  data: GatsbyKenroVoicePageComponentQuery;
};

const Component: VFC<Props> = (props) => {
  const { data } = props;

  const voice = data.contentfulVoice;

  const meta: MetaProps = {
    title: `${voice?.title} | KENRO` ?? undefined,
    description: voice?.description?.description ?? undefined,
    image: voice?.thumbnail?.file?.url
      ? `https:${voice.thumbnail.file.url}`
      : undefined,
    isSeparateSite: true,
    useTypeSquareFonts: true,
  };

  useEffect(() => {
    const hatenaHelper = document.createElement("script");
    hatenaHelper.src = "https://b.st-hatena.com/js/bookmark_button.js";
    document.body.appendChild(hatenaHelper);

    const twitterHelper = document.createElement("script");
    twitterHelper.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(twitterHelper);

    if (document.getElementById("script-facebook") === null) {
      const facebookHelper = document.createElement("script");
      facebookHelper.src =
        "https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v10.0";
      facebookHelper.id = "script-facebook";
      document.body.appendChild(facebookHelper);
    } else {
      (window as any).FB.XFBML.parse();
    }
  }, []);

  if (voice === undefined || voice === null) return <div>loading....</div>;

  return (
    <Kenro2PaneLayout meta={meta}>
      <main>
        {voice.title && (
          <div className={styles.Voice_TitleWrapper}>
            <p>KENRO導入事例インタビュー</p>
            <div className={styles.Voice_Title}>{voice.title}</div>
          </div>
        )}
        <div className={styles.Voice_ContentWrapper}>
          {voice.description?.description && (
            <p>{voice.description.description}</p>
          )}
          <div className={styles.Voice_ThumbnailAvatar}>
            {voice.thumbnail?.gatsbyImageData && (
              <GatsbyImage image={voice.thumbnail?.gatsbyImageData} alt="" />
            )}
          </div>
          {voice.body?.body && (
            <div>
              <ReactMarkdown rehypePlugins={[h6toFigcaptionPlugin]}>
                {voice.body.body}
              </ReactMarkdown>
            </div>
          )}
        </div>
        <div className={styles.Voice_ShareButtonWrapper}>
          <li className={styles.Voice_ShareButton}>
            <div className={styles.Voice_ShareButton__Twitter}>
              <a
                href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                className="twitter-share-button"
              >
                Tweet
              </a>
            </div>
          </li>
          <li className={styles.Voice_ShareButton}>
            <div
              className="fb-share-button"
              data-href={`https://flatt.tech/kenro/voice/${voice.slug}`}
              data-layout="button_count"
              data-size="small"
            >
              <a
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fflatt.tech%2FVoice&amp;src=sdkpreparse"
                className="fb-xfbml-parse-ignore"
                rel="noreferrer"
              >
                シェア
              </a>
            </div>
          </li>
          <li className={styles.Voice_ShareButton}>
            <a
              href="https://b.hatena.ne.jp/entry/"
              className="hatena-bookmark-button"
              data-hatena-bookmark-layout="basic-label-counter"
              data-hatena-bookmark-lang="ja"
              title="このエントリーをはてなブックマークに追加"
              data-show-count="false"
            >
              <img
                src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
                alt="このエントリーをはてなブックマークに追加"
                width="20"
                height="20"
              />
            </a>
          </li>
        </div>
      </main>
    </Kenro2PaneLayout>
  );
};

export default withContext(Component);

export const query = graphql`
  query KenroVoicePageComponent($slug: String!) {
    contentfulVoice(slug: { eq: $slug }) {
      id
      date
      slug
      title
      description {
        description
      }
      thumbnail {
        gatsbyImageData
        file {
          url
        }
      }
      client
      body {
        body
      }
    }
  }
`;
